import React from "react";
import { graphql, Link } from "gatsby";
import map from "lodash/map";
import Layout from "../components/layout/Layout";
import CourseDuration from "../components/course/CourseDuration";
import CourseDates from "../components/course/CourseDates";
import CourseLecturer from "../components/course/CourseLecturer";
import CoursePrice from "../components/course/CoursePrice";
import Hero from "../components/layout/Hero";
import SEO from "../components/seo/SEO";
import CourseShowcase from "../components/lists/CourseShowcase";
import CaptureInterest from "../components/course/CaptureInterest";

export default function CoursePage({
  data: {
    markdownRemark: {
      frontmatter: {
        image,
        durationDays,
        courseDates,
        lecturer,
        path,
        program,
        title,
        price,
        seoDescription,
        relatedCourses
      },
      html,
      excerpt
    }
  }
}) {
  return (
    <>
      <SEO
        title={`${title} kurssi / koulutus`}
        description={seoDescription || excerpt}
        image={`/images/${image}.svg`}
        path={path}
      />
      <Layout>
        <div className="mt-10">
          <Hero image={image}>{title}</Hero>

          <div className="lg:flex mb-5 mt-10">
            <div
              className="flex-3 mb-3 lg:mr-8 markdown-content"
              dangerouslySetInnerHTML={{ __html: html }}
            />

            <div className="flex-2">
              <div className="mb-3">
                <CourseDates courseDates={courseDates} />
              </div>
              <div className="mb-3">
                <CourseDuration durationDays={durationDays} />
              </div>
              <div className="mb-3">
                <CourseLecturer lecturer={lecturer} />
              </div>
              <div className="mb-3">
                <CoursePrice price={price} />
              </div>
              <div className="mb-10 mt-6">
                {courseDates ? (
                  <a href="/ilmoittaudu-kurssille" className="button">
                    Ilmoittaudu kurssille
                  </a>
                ) : (
                  <CaptureInterest
                    title="Kiinnostaako kurssi?"
                    text="Liity odotuslistalle niin olemme yhteydessä sinuun, kun tiedämme milloin
              seuraava toteutus pidetään."
                    courseTitle={title}
                  />
                )}
              </div>

              {courseDates ? (
                <div className="mb-6 mt-6">
                  <CaptureInterest
                    title="Eikö ajankohdat sovi sinulle?"
                    text="Liity odotuslistalle niin olemme yhteydessä sinuun, kun tiedämme seuraavista ajankohdista."
                    courseTitle={title}
                  />
                </div>
              ) : null}

              <div>
                <div className="text-indigo-800 font-bold">
                  Oma kurssi yrityksellesi?
                </div>
                <div>
                  <p>
                    Kun osallistujia on useampia samasta yrityksestä,{" "}
                    <Link to="/yritys#yrityskohtaiset">pyydä tarjous</Link>{" "}
                    omasta kurssista. Kurssin sisältöä voidaan räätälöidä
                    tarvittaessa.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {program ? (
            <>
              <div className="text-center">
                <h2 className="mt-10 mb-6 highlight-title">Ohjelma</h2>
              </div>

              <div
                className={`lg:mx-auto ${
                  program.length > 1 ? "lg:w-3/4" : "lg:w-2/5"
                }`}
              >
                <div className="sm:flex sm:-mx-2">
                  {map(program, ({ day, schedule }) => {
                    return (
                      <div
                        key={day}
                        className="sm:flex-1 sm:mx-2 sm:p-5 mb-5 sm:mb-0"
                      >
                        {program.length > 1 ? (
                          <div className="mb-3 font-bold text-xl">
                            Päivä {day}
                          </div>
                        ) : null}

                        {map(
                          schedule,
                          ({
                            startTime,
                            endTime,
                            title,
                            content
                            // contentDescription
                          }) => {
                            return (
                              <div key={title} className="mb-5">
                                <div className="font-bold mb-2">
                                  <span className="font-mono text-xs py-1 px-2 bg-red-500 text-white rounded-lg">
                                    {startTime}
                                    {endTime ? ` - ${endTime}` : null}
                                  </span>
                                  <span className="ml-2">{title}</span>
                                </div>
                                {/* {contentDescription ? (
                                  <div>{contentDescription}</div>
                                ) : null} */}
                                {content && content.length ? (
                                  <ul>
                                    {map(content, (item, i) => {
                                      return <li key={i}>{item}</li>;
                                    })}
                                  </ul>
                                ) : null}
                              </div>
                            );
                          }
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          ) : null}
        </div>

        {relatedCourses && relatedCourses.length ? (
          <div className="mt-12 text-center">
            <h2 className="highlight-title">Aiheeseen liittyviä kursseja</h2>
            <div className="flex mx-auto justify-center flex-wrap">
              {relatedCourses.map(courseId => {
                return (
                  <div key={courseId}>
                    <CourseShowcase courseId={courseId} />
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
      </Layout>
    </>
  );
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt(pruneLength: 300)
      frontmatter {
        title
        seoDescription
        path
        template
        date
        price
        durationDays
        categories
        title
        image
        lecturer
        courseDates {
          startDate
          endDate
          place
        }
        relatedCourses
        program {
          day
          schedule {
            startTime
            endTime
            title
            content
          }
        }
      }
    }
  }
`;
