import React from "react";

function CaptureInterest({ title, text, courseTitle }) {
  return (
    <div>
      <div className="text-indigo-800 font-bold">{title}</div>
      <p>{text}</p>
      <form
        name="odotuslista"
        action="/kiitos"
        method="POST"
        data-netlify="true"
        className="gap-4 sm:flex"
      >
        <input type="hidden" name="form-name" value="odotuslista" />

        <input
          type="email"
          required
          name="email"
          placeholder="Sähköpostiosoitteesi"
          className="px-4 py-2 border-gray-400 border-solid border rounded-lg flex-1 text-sm block w-full sm:w-auto"
        />
        <input
          type="text"
          name="kurssi"
          value={courseTitle}
          className="hidden"
        />
        <button type="submit" className="button text-sm mt-4 sm:mt-0">
          Lähetä
        </button>
      </form>
    </div>
  );
}

export default CaptureInterest;
