import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import _ from "lodash";
import CourseDuration from "../course/CourseDuration";

/*
  <div className="flex items-center justify-center w-full my-10 flex-wrap">
    <CourseShowcase courseId="react-redux" />
    <CourseShowcase courseId="react-perusteet" />
    <CourseShowcase courseId="tcpip" />
    <CourseShowcase courseId="wireshark" />
    <CourseShowcase courseId="ipv6" />
  </div>
*/

function CourseShowcase({ courseId }) {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(sort: { fields: [frontmatter___title], order: ASC }) {
        totalCount
        edges {
          node {
            id
            frontmatter {
              courseId
              path
              title
              image
              courseDates {
                startDate
                endDate
                place
              }
              categories
              durationDays
              date(formatString: "DD MMMM, YYYY")
            }
            fields {
              slug
            }
            excerpt
          }
        }
      }
    }
  `);

  const course = _.get(
    _.find(
      data.allMarkdownRemark.edges,
      item => _.get(item, "node.frontmatter.courseId") === courseId
    ),
    "node"
  );

  const { frontmatter } = course;

  return (
    <div className="flex flex-col items-center w-auto p-5">
      <div>
        <img
          src={`/images/${frontmatter.image}.svg`}
          alt=""
          className="w-32 inline-block m-3 h-24"
        />
      </div>

      <Link
        className="font-bold flex-1 text-indigo-800 text-xl no-underline flex items-center"
        to={frontmatter.path}
      >
        {frontmatter.title}
      </Link>

      <div className="text-center my-3">
        <CourseDuration durationDays={frontmatter.durationDays} />
      </div>

      <Link to={frontmatter.path}>Lue lisää</Link>
    </div>
  );
}

export default CourseShowcase;
