import React from "react";

function CourseLecturer({ lecturer }) {
  if (!lecturer) return null;

  return (
    <>
      <div className="text-red-600 font-bold">Kouluttaja</div>
      {lecturer}
    </>
  );
}

export default CourseLecturer;
